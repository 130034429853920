@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
.fade-anim img {
animation:fadeAnim 0.5s ease-out;
}

.rollIn {
    animation-name:fadeAnim;
    animation-iteration-count: 2;
    animation-duration: 10s;
}

body{
    overflow-x:hidden;
}

@keyframes fadeAnim{
    from{
        opacity: 0;
        margin-top: -100px;
        transform: rotateZ('45deg')
    }
    to{
        opacity: 1;
        margin-top: 0px;
        transform: rotateZ('0deg')

    }
}

input[type=checkbox] {
    font-size: "30px";
}
input[type="checkbox"]:checked {
    color: grey
}

*:focus {
    outline: none;
    border: '3px';
}

.fade-down {
    animation: fadeDown 1s ease-out infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -1s);
    animation-iteration-count: 100
}

@keyframes fadeDown {
    0% {
      translate: 0px -50px;
      opacity: 0;
      
    }
    50%{
      translate: 0px 0px;
      opacity: 1;
    }
    100%{
        translate: 0px 50px;
        opacity: 0;
    }
   
  }
  /* .vid-container {
    background-color: rgb(245, 245, 245);
    width: 100%;
    height: 200%;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  /* #youtubeVideo {
    height:100%;
    width:100% !important;
    background-size:100% 100%;
    position:relative;
    overflow:hidden;
} */
.gm-style-iw {
  width: 400px!important;
  height: 500px!important;
  left: 0!important;
  font-size: 15px!important;
  font-weight: normal!important;
  top: 0!important;
  overflow-x: hidden;
  overflow-y: hidden;
  border-radius: 3px;
  /* left: 12px; top: 9px; overflow: auto; */
}

/* hide close button in info-window */
::ng-deep button.gm-ui-hover-effect {
  visibility: hidden;
}

/* clear the paddings  */
::ng-deep .gm-style .gm-style-iw-c {
  padding: 0;
}

/* remove the white space */
::ng-deep .gm-style-iw .gm-style-iw-d {
  overflow: auto !important;
}
  #map {
    height: 100%;
    width: 100%;
    position: "relative"
  }
  [data-initials]:before {
    background: #f3e7cc;
    color: #CBA356;
    opacity: 1; 
    content: attr(data-initials); 
    display: inline-block; 
    font-weight: bold; 
    border-radius: 50%; 
    vertical-align: middle; 
    margin-right: 0.5em; 
    width: 50px; 
    height: 50px; 
    line-height: 50px; 
    text-align: center; 
    /* font-size: 1.5rem; */
    font-family:"poppins";
    }
    #youtubeVideo {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
  }
  #youtubeVideo iframe{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  
::-webkit-scrollbar {
    width: 6px;
    padding: 2px
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(128, 128, 128, 0);
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(187, 187, 187); 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #767676; 
  }

@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-dots{
    display: flex !important;
    justify-content: end;
    position: absolute;
    top: 0;
}

.slick-dots li button::before{
    color: #CBA356 !important;
    font-size: 0.5rem;
}
/* .slck */
html,body{
    overflow-x:hidden,
}
*body{
    font-display: swap;
}
/* .slck */

.react-calendar {
    border: none;
}
.poppins{
    font-family: 'Poppins', sans-serif;
}